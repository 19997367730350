((window, document) => {
  // If we're on a page with no brand ref, set it via the URL
  if (typeof window.qBrandRef === 'undefined') {
    const currentHost = window.location.hostname;
    if (/vertumotors|vm\.web\.vertu-dev/.test(currentHost)) {
      window.qBrandRef = '84';
    } else if (/bristolstreet|bsm\.web\.vertu-dev/.test(currentHost)) {
      window.qBrandRef = '28';
    }
  }

  const { qBrandRef: brandRef } = window;
  const isVM = brandRef === '84';
  const desktopDown = window.matchMedia('(max-width: 1280px)');
  const blockName = 'main-nav';
  const jsBlockName = `.js-${blockName}`;
  const jsUspBanner = '.js-usp-banner';
  const panelName = 'panel';
  const primaryNav = document.querySelector(jsBlockName);
  const primaryNavMenu = primaryNav.querySelector(`${jsBlockName}__menu`);
  const primaryNavMenuItems = primaryNavMenu.querySelectorAll(`${jsBlockName}__item`);
  const servicingForm = primaryNav.querySelector(`${jsBlockName}__servicing-form`);
  const mobileMenuButton = document.querySelector(`${jsBlockName}__drawer--open`);
  const mobileMenuBackButtons = document.querySelectorAll(`${jsBlockName}__back-button`);
  const searchButton = document.querySelector(`${jsBlockName}__search-init`);
  const searchCloseButton = document.querySelector(`${jsBlockName}__search-close`);
  const searchInput = primaryNav.querySelector('.js-a-search-input');
  const searchOpenClass = `${blockName}__search--open`;
  const bodyNavOpenClass = `${blockName}-${panelName}-open`;
  const panelOpenClass = `${blockName}__${panelName}--open`;
  const panelOpenSelector = `.${panelOpenClass}`;
  const rangeSelectors = document.querySelectorAll('.js-cash-range');
  const motabilityRangeSelector = document.querySelectorAll('.js-motability-range');
  const motabilityBodyStyleLinksContainer = document.querySelector(
    '.js-main-nav-motability-bodystyle-links',
  );
  let overlay = null;

  /**
   * @method handleUSPBannerModifier
   * Checks if the USP banner is not present on the page and adds a modifier
   * for the main nav panel to display at top of the screen.
   */
  function handleUSPBannerModifier() {
    const notUspBanner = !document.querySelector(jsUspBanner);

    if (notUspBanner) {
      const mainNavPanels = document.querySelectorAll(`.${blockName}__${panelName}`);
      mainNavPanels.forEach((panel) => {
        panel.classList.add(`${blockName}__${panelName}--top-0`);
      });
    }
  }

  /**
   * @method clearSearchParams
   * Clears all session storage items that include "vehicleSearchParams"
   */
  function clearSearchParams() {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes('vehicleSearchParams')) {
        sessionStorage.removeItem(key);
      }
    });
  }

  /**
   * @method addmainNavCtaListener
   * Add click event listener to elements with class "main-nav__cta"
   */
  function addMainNavCtaListener() {
    const ctaElements = document.querySelectorAll('.main-nav__cta');
    ctaElements.forEach((cta) => {
      cta.addEventListener('click', clearSearchParams);
    });
  }

  /**
   * @method clearCheckboxesFromSessionStorage
   * Clears all session storage items that include the word "Checkboxes"
   */
  function clearCheckboxesFromSessionStorage() {
    Object.keys(sessionStorage).forEach((key) => {
      if (key.includes('Checkboxes')) {
        sessionStorage.removeItem(key);
      }
    });
  }

  /**
   * @method resetMenus
   * Resets all the menus and tab positions to their default values
   * @param allTabsList An array for the tabs
   */
  function resetMenus(allTabsList) {
    allTabsList.forEach((tabList) => {
      tabList.resetTabIndex();
    });
  }

  /**
   * @method closeAllPanels
   * Close all the open nav panels and remove the overlay
   */
  function closeAllPanels() {
    primaryNavMenuItems.forEach((item) => {
      const currentPanel = item.parentNode.querySelector(`.${blockName}__${panelName}`);
      currentPanel.classList.remove(`${blockName}__${panelName}--open`);
    });
    overlay = null;
    const existingOverlay = document.querySelector(`.${blockName}__overlay`);
    if (existingOverlay) {
      document.body.removeChild(existingOverlay);
    }
    primaryNavMenu.classList.remove(`${blockName}__menu--open`);
    mobileMenuButton.classList.remove(`${blockName}__hamburger--close`);
    document.body.classList.remove(bodyNavOpenClass);
  }

  /**
   * @method buildOverlay
   */
  function buildOverlay() {
    overlay = document.createElement('div');
    overlay.classList.add(`${blockName}__overlay`);
    document.body.appendChild(overlay);
    document.body.classList.add(bodyNavOpenClass);
    overlay.addEventListener('click', closeAllPanels);
  }

  /**
   * @method removeOverlay
   */
  function removeOverlay() {
    overlay = null;
    const existingOverlay = document.querySelector(`.${blockName}__overlay`);
    document.body.removeChild(existingOverlay);
    document.body.classList.remove(bodyNavOpenClass);
    closeAllPanels();
  }

  /**
   * @method overlayHandler
   * Creates or detroys the page overlay when a menu item is clicked
   * Appends a class to the body element to prevent scrolling
   */
  function overlayHandler() {
    const mobileMenuOpen = document.querySelector(`.${blockName}__menu--open`);
    const desktopMenuOpen = document.querySelectorAll(`.${blockName}__${panelName}--open`).length;
    if (!overlay) {
      buildOverlay();
    } else if ((!mobileMenuOpen && !desktopMenuOpen) || (!mobileMenuOpen && desktopDown.matches)) {
      removeOverlay();
    }
  }

  /**
   * @method menuInteractionHandler
   * @param {*} e The event passed when a menu item is clicked, used to determine action type
   * Runs when a menu item is clicked and shows or hides the relevant menu panel
   */
  function menuInteractionHandler(e, allTabsList) {
    resetMenus(allTabsList);
    const targetPanel = e.target.closest(`.${blockName}__item`).nextElementSibling;
    const targetPanelOpen = targetPanel.classList.contains(panelOpenClass);

    if (targetPanelOpen) {
      targetPanel.classList.remove(panelOpenClass);
    } else {
      document
        .querySelectorAll(panelOpenSelector)
        .forEach((p) => p.classList.remove(panelOpenClass));
      targetPanel.classList.add(panelOpenClass);
    }
    overlayHandler();
  }

  /**
   * @method addMobileMenuEventListener
   * Add the event listener to toggle the mobile menu
   */
  function addMobileMenuEventListener() {
    mobileMenuButton.addEventListener('click', () => {
      primaryNavMenu.classList.toggle(`${blockName}__menu--open`);
      mobileMenuButton.classList.toggle(`${blockName}__hamburger--close`);
      overlayHandler();
    });
  }

  /**
   * @method searchInteractionHandler
   * @param {boolean} open
   * Show or hide the search overlay
   */
  function searchInteractionHandler(open) {
    if (open) {
      closeAllPanels();
      primaryNav.classList.add(searchOpenClass);
    } else {
      primaryNav.classList.remove(searchOpenClass);
    }
  }

  /**
   * @method addSearchEventListeners
   * Add the click event listeners for search open/close
   */
  function addSearchEventListeners() {
    searchButton.addEventListener('click', () => {
      searchInteractionHandler(true);
      const mobileMenuOpen = primaryNavMenu.classList.contains(`${blockName}__menu--open`);
      if (mobileMenuOpen) {
        primaryNavMenu.classList.toggle(`${blockName}__menu--open`);
        mobileMenuButton.classList.toggle(`${blockName}__hamburger--close`);
      }
    });
    searchCloseButton.addEventListener('click', () => {
      searchInteractionHandler(false);
    });

    if (searchInput) {
      searchInput.addEventListener('focus', () => {
        primaryNav.classList.add('search-is-focused');
      });

      searchInput.addEventListener('blur', () => {
        primaryNav.classList.remove('search-is-focused');
      });
    }
  }

  /**
   * @method addRangeEventListeners
   * Add the click handlers for the range links
   * i.e. New cars by budget £10,000 - £15,000
   */
  function addRangeEventListeners() {
    rangeSelectors.forEach((item) => {
      item.addEventListener('click', (e) => {
        clearCheckboxesFromSessionStorage();
        const minPrice = e.target.dataset.min;
        const maxPrice = e.target.dataset.max;
        const searchType = e.target.closest('ul').dataset.searchtype;
        const searchTypeMappings = {
          newcars: {
            sessionKey: `${isVM ? 'new-car-deals' : 'new-cars'}/NC`,
          },
          newcarsinstock: {
            sessionKey: 'new-cars-in-stock/NCIS',
          },
          newcarselectric: {
            sessionKey: 'new-car-deals/electric-cars/NCelectric',
          },
          usedcars: {
            sessionKey: 'used-cars/UC',
          },
          usedcarsapproved: {
            sessionKey: 'used-cars/UC',
            keyFeatures: ['manuApproved'],
          },
          usedcarselectric: {
            sessionKey: 'used-cars/electric-cars/UCelectric',
          },
          usedvans: {
            sessionKey: 'used-vans/UL',
          },
          newvans: {
            sessionKey: 'new-vans/NL',
          },
          newvansinstock: {
            sessionKey: 'new-vans-in-stock/NLIS',
          },
          newvanselectric: {
            sessionKey: 'new-van-deals/electric-vans/NLelectric',
          },
        };
        const { sessionKey = null, keyFeatures = null } = searchTypeMappings[searchType] || {};
        const sessionKeyFinal = `vehicleSearchParams/${sessionKey}${brandRef}Search`;
        const sessionValue =
          maxPrice !== 'null'
            ? { qsMinPrice: minPrice, qsMaxPrice: maxPrice, qsKeyFeatures: keyFeatures }
            : { qsMinPrice: minPrice, qsKeyFeatures: keyFeatures };
        sessionStorage.setItem(sessionKeyFinal, JSON.stringify(sessionValue));
      });
    });
  }

  /**
   * @method addMotabilityEventListener
   * Add the click handlers for the motability range links
   * i.e. Motability by budget nil - £500
   */
  function addMotabilityEventListener() {
    // Motability Range Selector
    const motabilitySessionKey = `vehicleSearchParams/motability/all/MB${brandRef}Search`;
    motabilityRangeSelector.forEach((item) => {
      item.addEventListener('click', (e) => {
        clearCheckboxesFromSessionStorage();
        const minPrice = e.target.dataset.min;
        const maxPrice = e.target.dataset.max;
        const motabiltySessionValue = { qsAdvancePayMin: minPrice, qsAdvancePayment: maxPrice };
        sessionStorage.setItem(motabilitySessionKey, JSON.stringify(motabiltySessionValue));
      });
    });

    // Motability BodyStyle links
    const motabilityBodyStyleLinks =
      motabilityBodyStyleLinksContainer.querySelectorAll('.main-nav__card-item a');

    motabilityBodyStyleLinks.forEach((item) => {
      item.addEventListener('click', () => {
        clearCheckboxesFromSessionStorage();

        const BODY_STYLES_TO_NOT_INCLUDE_IN_PARAMS = ['4x4', 'mpv', 'green'];
        const linkHref = item.href;
        const bodyStyleType = linkHref
          .substring(linkHref.indexOf('motability/') + 'motability/'.length)
          .replace(/\/$/, '');
        const bodyStyleSearchId = bodyStyleType.split('-')[0];

        if (BODY_STYLES_TO_NOT_INCLUDE_IN_PARAMS.includes(bodyStyleSearchId)) {
          return;
        }

        const motabiltyBodyStyleSessionValue = {
          qsBodyStyle: bodyStyleSearchId,
        };

        const motabilityBodyStyleSessionKey = `vehicleSearchParams/motability/${bodyStyleType}/MB${bodyStyleSearchId}${brandRef}Search`;
        sessionStorage.setItem(
          motabilityBodyStyleSessionKey,
          JSON.stringify(motabiltyBodyStyleSessionValue),
        );
      });
    });
  }

  /**
   * @method addMenuEventListeners
   * Add the click event listeners for each item in the menu
   * Add the close menu event listeners for other items
   */
  function addMenuEventListeners(allTabsList) {
    primaryNavMenuItems.forEach((item) => {
      item.addEventListener('click', (e) => menuInteractionHandler(e, allTabsList), false);
    });
    if (document.querySelector(jsUspBanner)) {
      document.querySelector(jsUspBanner).addEventListener('click', closeAllPanels);
    }

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        closeAllPanels();
      }
    });
  }

  /**
   * @method addMobileMenuBackButtonListener
   * Add the event listener to the back button on the mobile menu header
   */
  function addMobileMenuBackButtonListener() {
    mobileMenuBackButtons.forEach((item) => {
      item.addEventListener('click', (e) => {
        const currentPanel = e.target.closest(`.${blockName}__${panelName}`);
        currentPanel.classList.remove(`${blockName}__${panelName}--open`);
      });
    });
  }

  /**
   * @method addResizeEventListener
   * Add the resize event listener to remove the mobile (burger) menu button
   */
  function addResizeEventListener() {
    window.addEventListener('resize', () => {
      const isPanelOpen = document.querySelector(`.${blockName}__${panelName}--open`);
      const isMobileMenuOpen = primaryNavMenu.classList.contains(`${blockName}__menu--open`);
      const resizeFromDesktop = desktopDown.matches && isPanelOpen && !isMobileMenuOpen;

      // If resized to desktop down and the mobile menu is open, set menu button to close icon
      if (desktopDown.matches && isMobileMenuOpen) {
        mobileMenuButton.classList.add(`${blockName}__hamburger--close`);
      }

      if (resizeFromDesktop) {
        primaryNavMenu.classList.add(`${blockName}__menu--open`);
      }
      closeAllPanels();
    });
  }

  /**
   * @method addServicingSubmitHandler
   * Add the event listener for the submit button on the servicing menu
   */
  function addServicingSubmitHandler() {
    servicingForm.addEventListener('submit', (e) => {
      e.preventDefault();
      const inputReg = servicingForm.querySelector('#js-main-nav_servicing-reg').value;
      sessionStorage.setItem('car reg', inputReg);
      const SERVICE_URL = '/service-repairs/car-servicing/book-online/';
      window.location.href = SERVICE_URL;
    });
  }

  /**
   * @method initTabs
   * @returns An array of the tabs and associated functions
   */
  function initTabs() {
    const tabLists = [
      { selector: '.js-vans-tablist' },
      { selector: '.js-newCars-tablist' },
      { selector: '.js-usedCars-tablist' },
      { selector: '.js-electric-tablist' },
    ];
    return window.TabsAutomaticBuilder(tabLists);
  }

  /**
   * @method init
   * Initialises the event listeners to control the main site menus
   */
  function init() {
    const allTabsList = initTabs();
    addMenuEventListeners(allTabsList);
    addMobileMenuEventListener();
    addMobileMenuBackButtonListener();
    addResizeEventListener();
    addServicingSubmitHandler();
    addSearchEventListeners();
    addRangeEventListeners();
    addMotabilityEventListener();
    addMainNavCtaListener();
    handleUSPBannerModifier();
  }

  window.vm.onload(init);
})(window, document);
